import { Notifier } from '@airbrake/browser'
import { createApp } from 'vue'
import I18NextVue from 'i18next-vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { setAppCallbacks } from './utils/appCallbacks'
import { privacy, dataLayer, configuration, hybrid, setupAirbrake } from '@dpgradio/creative'
import i18next from './utils/i18n'
import { CAMPAIGN_CONFIG, CAMPAIGN_NAME } from './utils/config'
import { useUserStore } from './store/user'
import { initializeTracking } from './utils/tracking'

import './assets/styles/themes.scss'

const initialize = async () => {
  // GET STATION
  await configuration.retrieveConfigForStation(CAMPAIGN_CONFIG.stationId)

  // PRIVACY
  privacy.initialize()

  // DATALAYER
  dataLayer.initialize()
  dataLayer.setCampaignDetails({
    brand: configuration.stationId,
    signupRequired: true,
    title: CAMPAIGN_CONFIG.title,
    type: 'signup_moment',
  })
  dataLayer.pushVirtualPageView()

  // PINIA
  const pinia = createPinia()

  // APP
  const app = createApp(App).use(hybrid).use(pinia).use(I18NextVue, { i18next })

  // I18N
  i18next.changeLanguage(CAMPAIGN_NAME)

  // AIRBRAKE
  if (import.meta.env.PROD) {
    const airbrake = await setupAirbrake(
      Notifier,
      {
        projectId: 511287,
        projectKey: '307103f3b4060deeb4f72468775fe766',
        environment: 'production',
      },
      app
    )
    airbrake.addFilter((notice) => {
      notice.context = {
        ...notice.context,
        tokenContext: useUserStore().radioToken ? hybrid.decodeRadioToken(useUserStore().radioToken) : null,
      }
      return notice
    })
  }

  // Tracking
  initializeTracking()

  // APP CALLBACKS
  setAppCallbacks()

  app.mount('#signup-client')
}

initialize()
