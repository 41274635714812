<template>
  <div v-if="answers.length" class="o-answers">
    <h2>{{ $t('wrongAnswers.title') }}</h2>
    <p>{{ $t('wrongAnswers.description') }}</p>

    <input v-model="query" class="o-input o-search" type="search" placeholder="Zoek op antwoord" />

    <div class="o-answers__list">
      <div
        v-for="answer in filteredAnswers"
        :key="answer.name + answer.location + answer.guessed_at"
        class="o-answers__answer"
      >
        <span class="o-answers__answer__dot" />
        <div class="o-answers__answer__content">
          <strong>{{ answer.answer }}</strong>
          <div class="o-answers__answer__meta">
            {{ formatDate(answer.guessed_at) }}
            door
            {{ answer.name }}
            uit
            {{ answer.location }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSignupStore } from '../store/signup'

const dateFormatter = new Intl.DateTimeFormat('nl-NL', {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  hour: 'numeric',
  minute: 'numeric',
})

export default {
  name: 'WrongAnswers',
  data() {
    return {
      query: '',
    }
  },
  computed: {
    ...mapState(useSignupStore, ['answers']),
    filteredAnswers() {
      return this.answers.filter((answer) => {
        return answer.answer.toLowerCase().includes(this.query.toLowerCase())
      })
    },
  },
  mounted() {
    this.fetchAnswers()
  },
  methods: {
    ...mapActions(useSignupStore, ['fetchAnswers']),
    formatDate(rawDate) {
      return dateFormatter.format(new Date(rawDate))
    },
  },
}
</script>

<style lang="scss">
.o-answers {
  background-color: rgb(var(--secondary-color));
  padding: 20px;
  color: white;

  h2 {
    margin: 0 0 10px 0;
  }

  p {
    margin: 0 0 10px 0;
  }

  &__list {
    margin: 16px -20px 0 -20px;
    padding: 10px 20px;
    background: white;

    max-height: 280px;
    overflow: auto;

    position: relative;
  }

  &__answer {
    background: white;
    color: rgb(var(--secondary-color));
    padding: 10px;
    margin-bottom: 10px;
    display: flex;

    &__dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 999999px;
      background: rgb(var(--primary-color));
      margin-right: 18px;
      margin-top: 6px;
    }

    &__content {
      flex: 1;
      font-family: var(--font-body);
    }

    &__meta {
      margin-top: 4px;
      color: #aeaeae;
      font-size: 0.8rem;
    }
  }
}

.o-search {
  border-radius: 999999px;
  padding-left: 20px;
}
</style>
