<template>
  <transition name="modal" appear>
    <div class="modal" @click.self="close()">
      <div class="modal-detail">
        <div v-if="originalMobile">
          <h2>Controleer je telefoonnummer</h2>
          <p>
            Als je wordt gekozen om mee te spelen, moeten we je natuurlijk kunnen bereiken! Controleer hieronder of je
            telefoonnummer klopt.
          </p>
        </div>
        <div v-else>
          <h2>Mogen we je nummer?</h2>
          <p>
            We hebben nog geen nummer van jou! Als je wordt gekozen om mee te spelen, moeten we je natuurlijk kunnen
            bereiken!
          </p>
        </div>
        <input v-model="mobile" class="o-input" type="tel" placeholder="Telefoonnummer" />

        <button class="button" :disabled="formState === 'loading'" @click="confirm()">
          <span v-if="formState === 'idle'">Bevestig</span>
          <span v-if="formState === 'loading'">Laden...</span>
          <span v-if="formState === 'error'">Er is een fout opgetreden</span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '../store/user'
import { removePhoneNumberCountryPrefix } from '@dpgradio/creative'

export default {
  name: 'MobileConfirmationPopup',
  emits: ['close', 'confirm'],
  data() {
    return {
      mobile: '',
      formState: 'idle',
    }
  },
  computed: {
    ...mapState(useUserStore, {
      originalMobile: (store) => store.profile.mobile,
    }),
  },
  mounted() {
    this.mobile = removePhoneNumberCountryPrefix(this.originalMobile)
  },
  methods: {
    ...mapActions(useUserStore, ['confirmMobile']),
    async confirm() {
      this.formState = 'loading'
      try {
        await this.confirmMobile(this.mobile)

        this.close()
        this.$emit('confirm')
      } catch (error) {
        this.formState = 'error'
        throw error
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-detail {
  background: white;
  width: 80%;
  padding: 20px;
}

.button {
  background-color: rgb(var(--q-red));
  color: white;

  &:disabled {
    background-color: rgb(var(--q-grey));
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.o-input {
  margin-bottom: 7px;
  border: 0.5px solid rgb(var(--q-grey));

  .slide-enter-active {
    transition: height 0.4s ease-in-out;
  }

  .error {
    &.slide-enter {
      height: 0px;
    }

    padding: 4px 20px;
    background-color: white;
    height: 20px;
    color: red;
  }
}
</style>
