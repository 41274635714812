<template>
  <transition name="modal" appear>
    <div class="modal" @click.self="close()">
      <div class="modal-detail">
        <div>
          <h2>{{ $t('tooMuchListens.title') }}</h2>
          <p>
            {{ $t('tooMuchListens.description') }}
          </p>
        </div>

        <button class="button" @click="close()">{{ $t('tooMuchListens.button') }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TooMuchListensPopup',
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-detail {
  background: white;
  width: 80%;
  padding: 20px;
}

.button {
  background-color: rgb(var(--q-red));
  color: white;

  &:disabled {
    background-color: rgb(var(--q-grey));
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
