import { privacy } from '@dpgradio/creative'
import mixpanel from 'mixpanel-browser'
import { CAMPAIGN_CONFIG } from './config'

let trackingEvents = false
let trackBacklog = []

export async function initializeTracking() {
  const mixpanelId = CAMPAIGN_CONFIG.mixpanelId

  if (!mixpanelId) {
    return
  }

  privacy.push('analytics', () => {
    mixpanel.init(mixpanelId, {
      track_pageview: true,
      persistence: 'localStorage',
      api_host: 'https://api-eu.mixpanel.com',
    })
    trackingEvents = true

    while (trackBacklog.length > 0) {
      const { eventName, properties } = trackBacklog.shift()
      mixpanel.track(eventName, properties)
    }
  })
}

export function trackEvent(eventName, properties) {
  if (trackingEvents) {
    mixpanel.track(eventName, properties)
  } else {
    trackBacklog.push({ eventName, properties })
  }
}
