<template>
  <div :class="theme">
    <div class="o-header">
      <div
        v-if="config.headerVideoId"
        class="mychannels"
        data-mychannels-type="video"
        data-mychannels-options="FUFFU_default"
        :data-mychannels-id="config.headerVideoId"
        style="min-width: 100%; aspect-ratio: 16/9"
      />
      <img v-if="config.headerImage" :src="config.headerImage" />

      <div class="o-header__overlay">
        <div v-show="currentValue" class="o-header__overlay-text">
          <div>{{ $t('campaign.name') }} is nu</div>
          <div class="amount">€{{ formattedGeluidValue }}</div>
          <div>waard</div>
        </div>
      </div>

      <p class="o-header__copy">
        {{ $t('campaign.description') }}
        <br />
        <a @click.prevent="openCampaignPageLink"> {{ $t('campaignPage.linkCopy') }} </a>
      </p>

      <div v-if="config.campaignEnded && config.campaignEndedLink" class="o-more-info">
        <button @click="openCampaignEndPageLink">{{ $t('campaignEndPage.linkCopy') }}</button>
      </div>
    </div>

    <div v-if="isLoadingSignUpMoment" class="o-info-loading">
      <!-- This state prevents flickering signup states that may confuse the user about their current sign up state after a restart,
        especially when using a slower connection or during high load. -->
      <h2>Laden...</h2>
    </div>

    <div v-else-if="isCampaignEnded" />

    <div v-else-if="hasActiveSignupMoment && !subscribed">
      <div class="o-form">
        <h2>{{ $t('signupMoment.title') }}</h2>
        <h1 v-if="isUsingSockets" class="o-timer">{{ duration }}</h1>
        <button :disabled="signUpState !== 'idle'" @click="signupForMoment()">
          <span v-if="signUpState === 'idle'"> {{ $t('signupMoment.running.btn') }}</span>
          <span v-if="signUpState === 'loading'">Laden...</span>
          <span v-if="signUpState === 'error'">Er is een fout opgetreden</span>
        </button>
        <OptIn v-if="config.optIn" v-model="consent" />
      </div>

      <div class="o-info">
        <p>
          {{ $t('campaign.extraInfo') }}
        </p>
      </div>
    </div>

    <div v-else-if="subscribed" class="o-subscribed">
      <img class="o-image--centered" src="./assets/ic_check.png" />
      <h2 class="o-title--centered">{{ $t('signupMoment.success.title', { moment: signedUpDate }) }}</h2>
      <p v-html="$t('signupMoment.success.description')" />
      <p v-html="$t('signupMoment.success.warning')" />
    </div>

    <div v-else class="o-form">
      <img class="o-image--centered" src="./assets/ic_time.png" />
      <h2 class="o-title--centered">{{ $t('signupMoment.preview.title') }}</h2>
      <p v-if="isCampaignLive">
        {{ $t('signupMoment.preview.description') }}
      </p>
      <p v-else>
        {{ $t('signupMoment.notRunning.description') }}
      </p>
    </div>

    <div v-if="config.sponsorLink" class="o-more-info">
      <img class="banner" :src="config.sponsorImage" @click="openBannerLink" />
    </div>

    <div v-if="config.sound && !config.campaignEnded" class="o-sound">
      <h2>{{ $t('soundListener.title') }}</h2>
      <p>
        {{ $t('soundListener.description') }}
      </p>
      <AudioPlayer :sound="config.sound" />
    </div>

    <WrongAnswers />

    <div class="o-more-info">
      <button @click="openCampaignPageLink">{{ $t('campaignPage.linkCopy') }}</button>
    </div>

    <MobileConfirmationPopup
      v-if="showMobileConfirmation"
      @close="closeMobileConfirmation"
      @confirm="signupForMoment()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { isToday } from 'date-fns'
import MobileConfirmationPopup from './components/MobileConfirmationPopup.vue'
import OptIn from './components/OptIn.vue'
import WrongAnswers from './components/WrongAnswers.vue'
import { useSignupStore } from './store/signup'
import { useUserStore } from './store/user'
import { useSocketStore } from './store/socket'
import { hybrid, openLink } from '@dpgradio/creative'
import { CAMPAIGN_CONFIG } from './utils/config'
import AudioPlayer from './components/AudioPlayer/AudioPlayer.vue'
import { pushConsent } from './utils/consent'

const numberFormatter = new Intl.NumberFormat('nl-BE')
const weekdayFormatter = new Intl.DateTimeFormat('nl-NL', { weekday: 'long' })

export default {
  name: 'App',
  components: {
    MobileConfirmationPopup,
    OptIn,
    WrongAnswers,
    AudioPlayer,
  },
  data() {
    return {
      showMobileConfirmation: false,
      signUpState: 'idle',
      config: null,
      consent: false,
    }
  },
  computed: {
    ...mapState(useSignupStore, [
      'currentValue',
      'signupMoment',
      'subscribed',
      'shouldConfirmMobile',
      'answers',
      'hasActiveSignupMoment',
    ]),
    ...mapState(useUserStore, ['initialize', 'shouldConfirmMobile', 'radioToken']),
    formattedGeluidValue() {
      return numberFormatter.format(this.currentValue)
    },
    currentMomentDate() {
      return new Date(this.signupMoment.hiddenAt)
    },
    theme() {
      return this.config.theme
    },
    signedUpDate() {
      if (!this.currentMomentDate || this.isUsingSockets) {
        return 'het volgende spelmoment'
      } else if (isToday(this.currentMomentDate)) {
        return 'vandaag'
      } else {
        return weekdayFormatter.format(this.currentMomentDate)
      }
    },
    geluidDataLoaded() {
      return this.geluidValue !== undefined && this.hasActiveSignupMoment !== null
    },
    isUsingSockets() {
      return this.config.type === 'sockets'
    },
    isLoadingSignUpMoment() {
      return !this.signupMoment
    },
    duration() {
      const minutes = Math.floor((this.signupMoment.duration % 3600) / 60)
        .toString()
        .padStart(2, '0')
      const seconds = Math.floor(this.signupMoment.duration % 60)
        .toString()
        .padStart(2, '0')
      return `${minutes}:${seconds}`
    },
    isCampaignLive() {
      return 'campaignActive' in this.config ? this.config.campaignActive : true
    },
    isCampaignEnded() {
      return this.config.campaignEnded ?? false
    },
  },
  created() {
    this.config = CAMPAIGN_CONFIG
  },
  async mounted() {
    this.initialize()

    this.fetchCurrentValue()
    if (this.isUsingSockets) {
      this.subscribeToSocket(this.config.stationId)
    } else {
      await this.fetchSignUpMoment()
    }
  },
  methods: {
    ...mapActions(useSignupStore, ['fetchSignUpMoment', 'fetchCurrentValue', 'checkUserSignedIn', 'signUp']),
    ...mapActions(useUserStore, ['askForLogin', 'initConfirmationRequirement', 'fetchProfile']),
    ...mapActions(useSocketStore, ['subscribeToSocket']),
    async signupForMoment() {
      if (!this.radioToken) {
        this.askForLogin()
      } else {
        this.signUpState = 'loading'
        try {
          await this.initConfirmationRequirement()

          if (this.shouldConfirmMobile) {
            this.showMobileConfirmation = true
          } else {
            await this.signUp()
          }
        } catch (error) {
          this.signUpState = 'error'
          throw error
        }

        if (this.consent) {
          const profile = await this.fetchProfile()
          await pushConsent(profile.email)
        }
      }
    },
    closeMobileConfirmation() {
      this.showMobileConfirmation = false
      this.signUpState = 'idle'
    },
    openBannerLink() {
      openLink(this.config.sponsorLink)
    },
    openCampaignPageLink() {
      if (hybrid.isNativeApp()) {
        hybrid.call('navigateTo', { url: this.config.campaignPageLink, inApp: true })
      } else {
        openLink(this.config.campaignPageLink)
      }
    },
    openCampaignEndPageLink() {
      if (hybrid.isNativeApp()) {
        hybrid.call('navigateTo', { url: this.config.campaignEndedLink, inApp: true })
      } else {
        openLink(this.config.campaignEndedLink)
      }
    },
  },
}
</script>

<style lang="scss">
html,
body {
  background: rgb(var(--primary-color));
}

#signup-client {
  background: white;
}

h1,
h2,
h3 {
  font-family: var(--font-title);
  letter-spacing: var(--letter-spacing-title);
  text-transform: var(--title-text-transform);
  margin: 10px 0;
  font-weight: 100;
}

p {
  font-family: var(--font-body);
}

.o-title {
  &--centered {
    text-align: center;
  }
}

input,
button {
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 0;
}

input {
  display: block;
  width: 100%;
  font-family: var(--font-body);
  font-size: 16px;
  padding: 12px;
  border: 0;
}

button {
  font-family: var(--font-title);
  letter-spacing: var(--letter-spacing-title);
  text-transform: var(--title-text-transform);
  background-color: white;
  color: rgb(var(--secondary-color));
  width: 100%;
  padding: 8px;
  font-size: 20px;
  font-weight: 600;
  border: 0;

  &:disabled {
    opacity: 0.5;
  }
}

a {
  color: rgb(var(--primary-color));
}

.o-form,
.o-subscribed {
  background-color: rgb(var(--primary-color));
  padding: 20px;
  color: white;

  h2 {
    margin-top: 0;
  }
}

.o-info {
  background-color: rgb(var(--primary-color));
  padding: 0px 20px 20px 20px;
  color: white;

  p {
    margin: 0;
  }
}

.o-info-loading {
  background-color: rgb(var(--secondary-color));
  padding: 20px;
  color: white;
  text-align: center;
}

.o-header {
  img {
    width: 100%;
    display: block;
    height: auto;
  }

  &__overlay {
    position: relative;
  }

  &__overlay-text {
    font-weight: 600;
    margin-top: 10px;

    div {
      text-align: center;
      width: 100vw;
      font-family: var(--font-title);
      letter-spacing: var(--letter-spacing-title);
      text-transform: var(--title-text-transform);
      color: rgb(var(--secondary-color));
      font-size: 22px;
      line-height: 22px;

      &.amount {
        color: rgb(var(--primary-color));
        font-family: var(--font-title);
        letter-spacing: var(--letter-spacing-title);
        text-transform: var(--title-text-transform);
        font-weight: 200;
        line-height: 38px;
        font-size: 40px;
        margin-top: 6px;
      }
    }
  }

  &__copy {
    padding: 0 20px;
    white-space: pre-line;
  }
}

.o-image {
  &--centered {
    display: block;
    width: 64px;
    margin: 25px auto;
  }
}

.o-more-info {
  padding: 20px;

  button {
    color: white;
    background-color: rgb(var(--primary-color));
  }
}

.o-timer {
  text-align: center;
  font-size: 6em;
}

.banner {
  width: 100%;
}

.o-sound {
  padding: 20px;
  color: rgb(var(--secondary-color));
}

body {
  margin: 0;
  font-family: var(--font-body);
  color: rgb(var(--secondary-color));
}
</style>
