import i18next from 'i18next'
import configs from './config'

i18next.init({
  fallbackLng: 'het_geluid_nl',
  resources: Object.keys(configs).reduce((extractedConfigs, key) => {
    extractedConfigs[key] = {
      translation: configs[key].translation,
    }
    return extractedConfigs
  }, {}),
})

export default i18next
