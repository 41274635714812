import { api } from '@dpgradio/creative'

let consentQuestion = null

export function hasGivenConsent() {
  return !!localStorage.getItem('consent-given-het-geluid')
}

export async function getConsentQuestion() {
  if (consentQuestion) {
    return consentQuestion
  }
  const { question } = await api.request().get('crm_consent/question')
  consentQuestion = question
  return question
}

export async function pushConsent(email) {
  await api.request().post('crm_consent/subscribe', {
    email: email,
    question: consentQuestion,
    touchpoint_url: window.location.href,
  })

  localStorage.setItem('consent-given-het-geluid', 'true')
}
