<template>
  <svg class="play-button" viewBox="0 0 20 20" @click="toggleAudio()">
    <path
      v-show="!isPlaying"
      fill-rule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
    />
    <path
      v-show="isPlaying"
      fill-rule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlayButton',
  props: ['isPlaying'],
  methods: {
    toggleAudio() {
      this.$emit('toggleAudio')
    },
  },
}
</script>

<style lang="scss" scoped>
.play-button {
  height: 45px;
  margin-right: 0.5rem;
  fill: rgb(var(--accent-color));
}
</style>
