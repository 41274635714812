import csuBanner from '../assets/het-geluid-2024-nl/csu_banner.gif'
import headerImage2024BE from '../assets/het-geluid-2024-be/header_image.jpg'

/*
    type: welk type aanmeldmoment is het? sockets / api
    theme: welk merk is het aanmeldmoment voor? qmusic / joe
    copy: {
        title: titel van het aanmeldmoment
        description: beschrijving van het aanmeldmoment
        button: tekst op de aanmeldknop
        success: {
            title: titel van de succesmelding
            description: beschrijving van de succesmelding
            button: tekst op de succesknop
        }
    }
*/

const campaign = new URLSearchParams(window.location.search).get('campaign') || 'het_geluid_nl'

const configs = {
  het_geluid_nl: {
    stationId: 'qmusic_nl',
    theme: 'qmusic',
    type: 'api',
    title: 'Het Geluid NL',
    optIn: true,
    headerVideoId: 766893,
    mixpanelId: '594a9082a210d6c538a93a2acdc8411a',
    campaignPageLink: 'https://qmusic.nl/embed/acties/het-geluid-2024',
    sponsorImage: csuBanner,
    sponsorLink: 'https://qrad.io/4f8sdPW',
    translation: {
      campaign: {
        name: 'Het Geluid',
        description: `Denk jij te weten wat Het Geluid is? Dan ben jij klaar om mee te spelen! Meld je hieronder elke dag aan en maak kans om Het Geluid te raden.`,
        extraInfo: '',
      },
      campaignPage: {
        linkCopy: 'Lees meer over Het Geluid',
      },
      wrongAnswers: {
        title: 'Foute antwoorden',
        description: 'Dit zijn de foute antwoorden die al gegeven zijn op de radio.',
      },
      signupMoment: {
        title: 'Meld je aan voor Het Geluid',
        preview: {
          title: 'Elke werkdag',
          description: `Meespelen met Het Geluid kan enkel van maandag tot en met vrijdag, 7 tot
                19u. Laat je brein dus nog even rusten en kom binnenkort terug om keihard mee te zoeken!`,
        },
        running: {
          btn: 'Meld mij aan',
        },
        success: {
          title: 'Je bent aangemeld voor {{moment}}!',
          description: `<strong>Houd je telefoon in de gaten</strong>, wie weet pikt onze computer jou er uit om mee te spelen.`,
          warning: `<strong>Let op:</strong> morgen moet je je opnieuw aanmelden.`,
        },
      },
    },
  },
  het_geluid_be: {
    stationId: 'qmusic_be',
    theme: 'qmusic',
    type: 'api',
    title: 'Het Geluid BE',
    headerImage: headerImage2024BE,
    campaignPageLink: 'https://qmusic.be/embed/kom-hier-alles-te-weten-over-het-geluid-2024',
    campaignEndedLink: 'https://qmusic.be/embed/check-hier-wat-het-was',
    mixpanelId: '5796350b8d04ac9c3ab5759b4a62f743',
    sound: {
      url: 'https://cdn-radio.dpgmedia.net/store/media/het_geluid_2024_1.mp3',
      code: 'het-geluid-2024-1',
      loginRequired: new Date() < new Date('2024-09-09T06:00:00.000+0200'),
      maxPlays: new Date() < new Date('2024-09-09T06:00:00.000+0200') ? 1 : null,
    },
    campaignActive: 6 <= new Date().getHours() && new Date().getHours() < 19 && new Date().getDay() < 6,
    campaignEnded: true,
    translation: {
      campaign: {
        name: 'Het Geluid',
        description: `Het Geluid is geraden! Check hieronder wat Het Geluid is!`,
      },
      campaignPage: {
        linkCopy: 'Lees meer over Het Geluid',
      },
      campaignEndPage: {
        linkCopy: 'Bekijk hier de video',
      },
      wrongAnswers: {
        title: 'Foute antwoorden',
        description: 'Deze antwoorden zijn al gegeven en zijn niet Het Geluid.',
      },
      signupMoment: {
        title: 'Meld je aan voor Het Geluid',
        preview: {
          title: 'Nog even geduld',
          description: `Meespelen met Het Geluid kan enkel van maandag tot en met vrijdag van 6u tot 19u. Laat je brein dus nog even rusten.`,
        },
        notRunning: {
          title: 'Nog even geduld',
          description: `Meespelen met Het Geluid kan enkel van maandag tot en met vrijdag van 6u tot 19u. Laat je brein dus nog even rusten.`,
        },
        running: {
          btn: 'Meld mij aan',
        },
        success: {
          title: 'Je bent aangemeld voor het komende spelmoment!',
          description:
            '<strong>Houd je telefoon in de gaten</strong>, wie weet pikt de computer jou eruit om mee te spelen.',
          warning: '',
        },
      },
      soundListener: {
        title: 'Luister naar Het Geluid',
        description: 'Spits je oren en raad wat het is.',
      },
      tooMuchListens: {
        title: 'Je hebt al geluisterd naar Het Geluid',
        description: `Je kan tot de start van Het Geluid maar 1 keer luisteren naar het geluid.`,
        button: 'Oke',
      },
    },
  },
  het_geluid_test: {
    stationId: 'test_station',
    theme: 'qmusic',
    type: 'api',
    title: 'Het Geluid BE',
    headerImage: headerImage2024BE,
    campaignPageLink: 'https://qmusic.be/embed/het-geluid-komt-terug',
    campaignEndedLink: 'https://qmusic.be/het-geluid',
    mixpanelId: '5796350b8d04ac9c3ab5759b4a62f743',
    sound: {
      url: 'https://cdn-radio.dpgmedia.net/store/media/het-geluid-2024.mp3',
      code: 'het-geluid-2024',
      loginRequired: new Date() < new Date('2024-09-06T06:00:00.000Z+0200'),
      maxPlays: new Date() < new Date('2024-09-06T06:00:00.000Z+0200') ? 1 : null,
    },
    campaignActive: 6 < new Date().getHours() && new Date().getHours() < 19 && new Date().getDay() < 6,
    campaignEnded: false,
    translation: {
      campaign: {
        name: 'Het Geluid',
        description: `Weet jij wat Het Geluid is? Speel hier mee! Meld je hieronder elk uur aan en maak kans om mee te raden.`,
        extraInfo: `Elk uur kan je je aanmelden om mee te spelen met het volgende spelmoment van Het Geluid. 
        Tijdens de Superronde om 9u30 is Het Geluid 25.000 euro waard.
        `,
      },
      campaignPage: {
        linkCopy: 'Lees meer over Het Geluid',
      },
      campaignEndPage: {
        linkCopy: 'Bekijk hier de video',
      },
      wrongAnswers: {
        title: 'Foute antwoorden',
        description: 'Deze antwoorden zijn al gegeven en zijn niet Het Geluid.',
      },
      signupMoment: {
        title: 'Meld je aan voor Het Geluid',
        preview: {
          title: 'Nog even geduld',
          description: `Meespelen met Het Geluid kan enkel van maandag tot en met vrijdag van 6u tot 19u. Laat je brein dus nog even rusten.`,
        },
        notRunning: {
          title: 'Nog even geduld',
          description: `Meespelen met Het Geluid kan enkel van maandag tot en met vrijdag van 6u tot 19u. Laat je brein dus nog even rusten.`,
        },
        running: {
          btn: 'Meld mij aan',
        },
        success: {
          title: 'Je bent aangemeld voor het komende spelmoment!',
          description:
            '<strong>Houd je telefoon in de gaten</strong>, wie weet pikt de computer jou eruit om mee te spelen.',
          warning: '',
        },
      },
      soundListener: {
        title: 'Luister naar Het Geluid',
        description: 'Spits je oren en raad wat het is.',
      },
      tooMuchListens: {
        title: 'Je hebt al geluisterd naar Het Geluid',
        description: `Je kan tot de start van Het Geluid maar 1 keer luisteren naar het geluid.`,
        button: 'Oke',
      },
    },
  },
}

export default configs
export const CAMPAIGN_NAME = campaign
export const CAMPAIGN_CONFIG = configs[campaign]
