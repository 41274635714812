import { api, hybrid, removePhoneNumberCountryPrefix } from '@dpgradio/creative'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    radioToken: null,
    shouldConfirmMobile: false,
    profile: null,
  }),
  actions: {
    initialize() {
      hybrid.appLoaded().then((radioToken) => {
        this.inApp = true
        if (radioToken) {
          this.setToken(radioToken)
        }
      })
      hybrid.on('authenticated', ({ radioToken }) => {
        this.setToken(radioToken)
      })
    },
    setToken(token) {
      this.radioToken = token

      api.setRadioToken(token)
    },
    askForLogin() {
      if (import.meta.env.DEV) {
        this.setToken(prompt('[DEVELOPMENT] Please enter your radio token:'))
        return
      }
      hybrid.call('showAuthentication', { tier: 'light' })
    },
    async fetchProfile() {
      if (!this.profile) {
        this.profile = await api.members.me()
      }
      return this.profile
    },
    async initConfirmationRequirement() {
      const confirmedMobile = localStorage.getItem('confirmedMobile')

      if (!confirmedMobile) {
        await this.fetchProfile()
      }

      this.shouldConfirmMobile = !confirmedMobile
    },
    async confirmMobile(mobile) {
      if (
        !this.profile.mobile ||
        removePhoneNumberCountryPrefix(this.profile.mobile) !== removePhoneNumberCountryPrefix(mobile)
      ) {
        await api.members.updateProfile({ mobile })
      }

      localStorage.setItem('confirmedMobile', true)
      this.shouldConfirmMobile = false
    },
  },
})
