<template>
  <div>
    <audio ref="player" class="audio" @ended="onEnd" @pause="onPause" @play="onPlay" @loadedmetadata="onLoadedMetadata">
      <source :src="sound.url" type="audio/mpeg" />
    </audio>

    <div class="audio-player">
      <PlayButton :isPlaying="isPlaying" @toggleAudio="toggleAudio" />
      <div class="progress">
        <div class="progress-bar" :style="progressStyle" />
      </div>
    </div>
    <TooMuchListensPopup v-if="showTooMuchListensPopup" @close="showTooMuchListensPopup = false" />
  </div>
</template>

<script>
import PlayButton from './PlayButton.vue'
import { dataLayer } from '@dpgradio/creative'
import { trackEvent } from '../../utils/tracking'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '../../store/user'
import TooMuchListensPopup from './TooMuchListensPopup.vue'

export default {
  components: { PlayButton, TooMuchListensPopup },
  props: ['sound'],
  data() {
    return {
      audioLoaded: false,
      isPlaying: false,
      totalDuration: 0,
      soundCount: 0,
      showTooMuchListensPopup: false,
    }
  },
  computed: {
    ...mapState(useUserStore, ['radioToken']),
    progressStyle() {
      return {
        animationDuration: `${this.totalDuration}s`,
        animationPlayState: this.isPlaying ? 'running' : 'paused',
      }
    },
    canPlaySong() {
      return this.sound.maxPlays ? this.soundCount < this.sound.maxPlays : true
    },
  },
  created() {
    this.soundCount = parseInt(localStorage.getItem(this.sound.code)) || 0
  },
  methods: {
    ...mapActions(useUserStore, ['askForLogin']),
    onEnd() {
      this.isPlaying = false
      const count = (parseInt(localStorage.getItem(this.sound.code)) || 0) + 1
      localStorage.setItem(this.sound.code, count)
      dataLayer.pushCampaignAction('sound_listened', { count })
      this.soundCount = count
      trackEvent('sound_listened', { count })
    },
    onPause() {
      this.isPlaying = false
    },
    onPlay() {
      this.isPlaying = true
    },
    onLoadedMetadata() {
      this.totalDuration = this.$refs.player.duration
    },
    toggleAudio() {
      if (this.$refs.player.paused) {
        if (this.sound.loginRequired && !this.radioToken) {
          this.askForLogin()
          return
        }

        if (!this.canPlaySong) {
          this.showTooMuchListensPopup = true
          return
        }

        dataLayer.pushCampaignAction('start_sound')
        trackEvent('start_sound')
        this.$refs.player.play()
      } else {
        this.$refs.player.pause()
        trackEvent('pause_sound', { time: this.$refs.player.currentTime })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.audio {
  display: none;
}

.audio-player {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  background-color: rgb(var(--primary-color));
  align-items: center;

  .progress {
    width: 100%;
    height: 30px;
    padding: 2px;
    border-radius: 30px;
    background-color: white;
  }

  .progress-bar {
    height: 100%;
    width: 0;
    min-width: 35px;
    border-radius: 30px;
    background-color: rgb(var(--primary-color));
    animation-name: grow;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes grow {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
