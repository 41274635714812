import { defineStore } from 'pinia'
import { dataLayer, api } from '@dpgradio/creative'
import { CAMPAIGN_CONFIG } from '../utils/config'
import { trackEvent } from '../utils/tracking'

export const useSignupStore = defineStore('signup', {
  state: () => ({
    subscribed: false,
    isSigningUp: false,
    signupMoment: null,
    currentValue: 0,
    answers: [],
  }),
  getters: {
    localStorageRegisteredKey() {
      return `signup_moment_${this.signupMoment.id}_registered`
    },
    hasActiveSignupMoment() {
      if (CAMPAIGN_CONFIG.type === 'sockets') {
        return this.signupMoment?.state === 'running'
      } else {
        return new Date() > new Date(this.signupMoment?.visibleAt) && new Date() < new Date(this.signupMoment?.hiddenAt)
      }
    },
  },
  actions: {
    async checkUserSignedUp() {
      if (!this.signupMoment || new Date(this.signupMoment.hiddenAt) < new Date()) {
        return
      }

      this.subscribed = localStorage.getItem(this.localStorageRegisteredKey) || false
    },
    async fetchCurrentValue() {
      const { amount } = await api.request().get('actions/het-geluid/amount')
      this.currentValue = amount
    },
    async fetchAnswers() {
      const { answers } = await api.request().get('actions/het-geluid/answers')
      this.answers = answers
    },
    async fetchSignUpMoment() {
      this.signupMoment = (await api.request().get('app/sign_up_moments/current')).current_moment
      await this.checkUserSignedUp()

      if (this.signupMoment) {
        const timeTillEnd = new Date(this.signupMoment.hiddenAt) - new Date() + 1000 // 1 second buffer
        setTimeout(() => this.fetchSignUpMoment(), timeTillEnd)
      }
    },
    async setSignupMoment(signupMoment) {
      const oldId = this.signupMoment?.id

      this.signupMoment = signupMoment

      if (oldId !== signupMoment.id) {
        this.checkUserSignedUp()
      }
    },
    async signUp() {
      if (this.isSigningUp) {
        return
      }

      this.isSigningUp = true

      try {
        await api.request().post(`app/sign_up_moments/${this.signupMoment.id}/sign_up`, { code: 'geluid' })

        dataLayer.pushCampaignAction('signup_success')
        this.subscribed = true
        localStorage.setItem(this.localStorageRegisteredKey, true)
        trackEvent('signup_success')
      } catch (e) {
        dataLayer.pushCampaignAction('signup_failure')
        trackEvent('signup_fail')
        throw e
      } finally {
        this.isSigningUp = false
      }
    },
  },
})
