<template>
  <div class="o-consent" v-if="!hasGivenConsent()">
    <div class="checkbox-group">
      <input id="checkbox" :value="consent" class="styled-checkbox" type="checkbox" @input="updateConsent" />
      <label for="checkbox"> {{ question }} </label>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getConsentQuestion, hasGivenConsent } from '../utils/consent'
const question = ref('')
const consent = ref(false)

const emit = defineEmits(['update:modelValue'])

onMounted(async () => {
  question.value = await getConsentQuestion()
})

const updateConsent = (event) => {
  consent.value = event.target.checked
  emit('update:modelValue', consent.value)
}
</script>

<style lang="scss" scoped>
.o-consent {
  margin-top: 1rem;
  font-family: var(--font-body);
}

.checkbox-group {
  display: flex;
  justify-content: left;
  text-align: left;
  font-size: 14px;

  .styled-checkbox {
    position: absolute;
    width: 0;
    opacity: 0;
    margin: 10px 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      margin: 5px;
      padding-left: 23px;
    }

    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      margin-left: -28px;
      width: 20px;
      height: 20px;
      background-color: white;
      border: 2px rgb(var(--q-light-grey)) solid;
      border-radius: 5px;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before {
      background: rgb(var(--q-grey));
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      left: 0px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
}
</style>
