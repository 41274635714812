import { socket } from '@dpgradio/creative'
import { defineStore } from 'pinia'
import { useSignupStore } from './signup'

export const useSocketStore = defineStore('socket', {
  state: () => ({
    socket,
  }),
  actions: {
    subscribeToSocket(station) {
      const stationSocket = socket.connect(station)
      const signupStore = useSignupStore()

      stationSocket.subscribe('app_button').on('update', (data) => signupStore.setSignupMoment(data), { backlog: 1 })

      this.socket = stationSocket
    },
  },
})
