import { api, hybrid } from '@dpgradio/creative'
import { useSignupStore } from '../store/signup'

export async function setAppCallbacks() {
  const signupStore = useSignupStore()

  hybrid.on('authenticated', ({ radioToken }) => {
    api.setRadioToken(radioToken)
    signupStore.checkUserSignedUp()
  })

  hybrid.on('didAppear', () => {
    signupStore.checkUserSignedUp()
  })

  const radioToken = await hybrid.appLoaded()

  if (radioToken) {
    api.setRadioToken(radioToken)
    signupStore.checkUserSignedUp()
  }
}
